// Import Mixpanel library
let  mixpanel = require('mixpanel-browser');

// Utility functions for Mixpanel integration
const GetEventFactories = () => {
    /* Section Load */
    function SectionLoaded(page) {
        return {
            name: 'Section Loaded',
            properties: {
                page
            }
        };
    }

    /* Questions and Answers */
    function QuestionFocused(question) {
        return {
            name: 'Question Focused',
            properties: {
                question
            }
        };
    }

    function _QuestionAnswered(question, answer, from) {
        return {
            name: 'Question Answered',
            properties: {
                question,
                answer,
                from
            }
        };
    }

    function QuestionAnsweredFromPage(question, answer) {
        return _QuestionAnswered(question, answer, 'Page');
    }

    function QuestionsAnsweredFromDropdown(question, answer) {
        return _QuestionAnswered(question, answer, 'Dropdown');
    }

    function InputUpdated(input, value) {
        return {
            name: 'Input Updated',
            properties: {
                input,
                value
            }
        };
    }

    function OptionSelected(option) {
        return {
            name: 'Option Selected',
            properties: {
                option
            }
        };
    }

    function UserOnboarded() {
        return {
            name: 'User Onboarded'
        };
    }

    return {
        SectionLoaded,
        QuestionFocused,
        QuestionAnsweredFromPage,
        QuestionsAnsweredFromDropdown,
        InputUpdated,
        OptionSelected,
        UserOnboarded
    };
}

const eventFactories = GetEventFactories();


// Initialize Mixpanel with your project token
function initializeMixpanel() {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN
    mixpanel = token ? mixpanel : { init: () => {}, track: () => {}, people: { set: () => {}, increment: () => {} }, alias: () => {} }
	mixpanel.init(token);
}

initializeMixpanel();

// Track an event with optional properties
function trackEvent(event) {
    const { name, properties } = event;
    return
	mixpanel.track(name, properties);
}

// Track Section Loaded event with debounce
function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

const trackSectionLoadedEvent = debounce((page) => {
    return
    const event = eventFactories.SectionLoaded(page);
    trackEvent(event);
}, 1000);

// Set or update a user's profile
function setUserProfile(mixpanel, distinctId, properties) {
	mixpanel.people.set(distinctId, properties);
}

// Create an alias for a user identifier
function aliasUser(mixpanel, distinctId, alias) {
	mixpanel.alias(alias, distinctId);
}

// Increment a numeric property for a user
function incrementProperty(mixpanel, distinctId, property, incrementBy = 1) {
	mixpanel.people.increment(distinctId, property, incrementBy);
}

module.exports = {
	initializeMixpanel,
	trackEvent,
	setUserProfile,
	aliasUser,
	incrementProperty,
    ...eventFactories,
    trackSectionLoadedEvent
};