import React, { useEffect, useState} from 'react';
import './PreApprovalForm.scss';
import { TextInput, InputAutocompleteAddress } from '../../atoms/TextInput/TextInput';
import { FooterContent } from '../ResumeFooter/ResumeFooter';
import { OptionDropdownInner } from '../../atoms/OptionDropdown/OptionDropdown';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { isProduction } from '../../../utils/constants';
import { SendLoanFormSubmitted } from '../SendLoanFormSubmitted/SendLoanFormSubmitted';
import { sendLeadData } from '../SendLoanForm/SendLoanForm';

/* Utility Functions */
// Function to format phone number
export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters
  let digits = phoneNumber.replace(/\D/g, '');

  // Limit to 10 digits
  digits = digits.substring(0, 10);

  // Format as (123) 456-7890
  const formatted = `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6)}`;

  return formatted;
}

/* Spinners */
const CustomSpinner = ({ color }) => (
  <Spin 
    indicator={
      <LoadingOutlined 
        style={{ 
          fontSize: 14, 
          color: color, 
          position: "relative", 
          bottom: "1px"
        }} 
        spin 
      />
    } 
  />
);

const WhiteSpinner = () => <CustomSpinner color="white" />;
const BlueSpinner = () => <CustomSpinner color="#162E43" />;

/* Components */
export const PreApprovalForm = (props) => {
  const { values, onContinue, borrowerData } = props;

  const isProcessing = Boolean(borrowerData)
  const hidePasswordFields = isProcessing || (!isProcessing && isProduction);

  const [firstName, setFirstName] = useState(borrowerData?.name || "")
  const [lastName, setLastName] = useState(borrowerData?.lastName || "")
  const [email, setEmail] = useState(borrowerData?.email || "")
  const [phoneNumber, setPhoneNumber] = useState(borrowerData?.phone || "")
  const [password, setPassword] = useState(hidePasswordFields ? "password" : "")
  const [passwordConfirmation, setPasswordConfirmation] = useState(hidePasswordFields ? "password" : "")
  const [investmentAddress, setInvestmentAddress] = useState("")

  const [isLoading, setIsLoading] = useState(false)

  const [errors, setErrors] = useState({});

  const userData = {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    passwordConfirmation,
    investmentAddress
  }

  const [addressSelected, setAddressSelected] = useState(false)

  const isFormComplete = () => {
    return Object.values(userData).every(value => value !== undefined && value !== '');
  }

  const validateInput = (label, value) => {
    switch (label) {
      case "First name":
      case "Last name":
        return value.trim() === "" ? "This field is required" : null;
      case "Investment Address":
        return value.trim() === "" ? "We need this to generate your pre-approval letter and term sheet" : null;
      case "Email address":
        const emailRegex = /\S+@\S+\.\S+/;
        return !emailRegex.test(value) ? "Invalid email address" : null;
      case "Phone number":
        return value.trim() === "" ? "This field is required" : null;
      case "Password":
        return value.length < 8 ? "Must be at least 8 characters" : null;
      case "Confirm Password":
        return !password || !value || value !== password ? "Passwords do not match" : null;
      default:
        return null;
    }
  };

  const checkErrors = () => {
    const newErrors = {
      firstName: validateInput("First name", firstName),
      lastName: validateInput("Last name", lastName),
      email: validateInput("Email address", email),
      phoneNumber: validateInput("Phone number", phoneNumber),
      password: validateInput("Password", password),
      passwordConfirmation: validateInput("Confirm Password", passwordConfirmation),
      investmentAddress: validateInput("Investment Address", investmentAddress),
    };
    setErrors(newErrors);
    return newErrors
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      checkErrors();
    }
  }, [firstName, lastName, email, phoneNumber, password, passwordConfirmation, investmentAddress])

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const handleContinue = (isKeepMeInTheLoop) => {
    const errors = checkErrors();
    const hasErrors = Object.values(errors).some(error => error !== null);

    if (!investmentAddress) {
      setAddressSelected(true)
    }

    if (isKeepMeInTheLoop === true) {
      const {investmentAddress, ...errs} = errors
      const {investmentAddress: _, ...ud} = userData

      const isFormComplete = Object.values(ud).every(value => value !== undefined && value !== '');
      const hasErrors = Object.values(errs).some(error => error !== null);

      console.log({isKeepMeInTheLoop, isFormComplete, hasErrors})

      if (isFormComplete && !hasErrors) {
        const { password, passwordConfirmation, ...leadData } = userData
        sendLeadData({...leadData, isProduction })
        setShowConfirmationModal(true)
      }
      
      return
    }

    if (isFormComplete() && !hasErrors) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
        onContinue(userData);
      }, 1000)
    }
  }

  const hasErrors = Object.values(errors).some(error => error !== null);

  const options = [
    {
      value: "the borrower",
      label: "the borrower",
      disabled: false,
      className: undefined
    },
    {
      value: "a broker",
      label: "a broker",
      disabled: false,
      className: undefined
    },
    {
      value: "an agent/attorney",
      label: "an agent/attorney",
      disabled: false,
      className: undefined
    },
    {
      value: "someone else",
      label: "someone else",
      disabled: false,
      className: undefined
    },
  ]

  const w = (f) => (a) => { if (!borrowerData) f(a) } 

  const title = borrowerData ? "Confirm a few details to get your term sheet and pre-approval letter" : "Enter your info to get a term sheet and pre-approval letter"
  const button = "Submit Application"

  useEffect(() => {
    if (investmentAddress) {
      setAddressSelected(false)
    }
  }, [investmentAddress])

  if (showConfirmationModal) {
    return (
      <SendLoanFormSubmitted isWhite={true} />
    )
  }

  return (
    <div className='pre-approval-form'>
      <div className='first-section'>
        <h1>{title}</h1>
        <div className='inputs-container'>
          <TextInput label={"First name"} value={firstName} onChange={w(setFirstName)} error={errors.firstName} />
          <TextInput label={"Last name"} value={lastName} onChange={w(setLastName)} error={errors.lastName} />
          <TextInput label={"Email address"} value={email} onChange={w(setEmail)} error={errors.email} />
          <TextInput label={"Phone number"} value={phoneNumber} onChange={w((e) => setPhoneNumber(formatPhoneNumber(e)))} error={errors.phoneNumber} />
          {
            hidePasswordFields ?
              null
              :
              <React.Fragment>
                <TextInput isPassword label={"Password"} value={password} onChange={(e) => setPassword(e)} error={errors.password} />
                <TextInput isPassword label={"Confirm Password"} value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e)} error={errors.passwordConfirmation} />
              </React.Fragment>
          }
        </div>
        
        <div className='borrower'>
          {
            borrowerData ?
              null
              :
              <p>I am <OptionDropdownInner defaultValue={options[0]} options={options} customWidth={"200px"} /></p>
          }
        </div>

        <div className='property'>
          <TextInput error={errors.investmentAddress}>
            <InputAutocompleteAddress
              onSelect={({ formatted_address }) => {
                const addressWithoutCountry = formatted_address.replace(/, [^,]*$/, "");
                setInvestmentAddress(addressWithoutCountry);
              }}
            />
          </TextInput>
          <AddressCaptionInner {...{ addressSelected, setAddressSelected }} />
        </div>
        {
          !addressSelected ?
            <div className='resume-container'>
              <FooterContent values={values} oneLine={true} isSpan={true} firstPerson />
            </div>
            :
            null
        }

        <div>
          {
            !addressSelected || borrowerData ?
              <Button isLoading={isLoading} disabled={hasErrors} onClick={handleContinue}>
                {button}
              </Button>
              :
              <>
                {
                  !investmentAddress  ?
                    <Button outlined isLoading={isLoading} onClick={() => handleContinue(true)}>
                      Keep me in the loop
                    </Button>
                    :
                    <Button isLoading={isLoading} disabled={hasErrors} onClick={handleContinue}>
                      {button}
                    </Button>
                }
              </>
          }
          <span className='caption'>By submitting this form you agree to be contacted by Constitution Lending</span>
        </div>

      </div>
    </div>
  );
}

export const AddressCaptionInner = (props) => {
  const {
    addressSelected,
    setAddressSelected,
    caption = "I don’t have specific property in mind"
  } = props;
  const addressCopy = "We need a property address to give you a loan, but if you’re considering options, one of our loan officers would love to talk to you anyway"

  return (
    <React.Fragment>
      <p
        className={addressSelected ? 'address-short-caption selected' : 'address-short-caption'}
        onClick={() => { setAddressSelected(!addressSelected) }}>
        {caption}
      </p>
      {
        addressSelected ?
          <p className='address-caption'>{addressCopy}</p>
          :
          null
      }
    </React.Fragment>
  )
}

export const AddressCaption = () => {
  const [addressSelected, setAddressSelected] = useState(false)
  
  return (
    <AddressCaptionInner {...{ addressSelected, setAddressSelected }} />
  )
}

export const Button = ({ children, onClick, disabled, isLoading, outlined = false }) => (
  <div className={`loan-options-button ${outlined ? 'outlined' : ''} ${disabled ? 'disabled' : ''}`} onClick={onClick}>
    {
      isLoading ?
        (
          outlined ?
            <BlueSpinner />
            :
            <WhiteSpinner />
        )
        :
        children
    }
  </div>
);