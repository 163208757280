import React from 'react';
import './SendLoanFormSubmitted.scss';

import { Icon } from '../../atoms/Icon/Icon';

export const SendLoanFormSubmitted = (props) => {
  const { isWhite } = props;

  return (
    <div className={`send-loan-form submitted ${isWhite ? 'white' : ''}`}>
      <div className='icon-container'>
        <Icon svgFilePath={"./assets/icons/icon-coins.svg"} />
      </div>
      <div className='info'>
        <h1 className='title'>
          Thanks for your submission!
        </h1>
        <h3 className='description'>
          Let us know if you have any other questions, otherwise we’ll be in touch within a week
        </h3>
        <div className='loan-options-button' onClick={() => { window.location.href='mailto:lend@constlending.com'; } }>
          Email us
        </div>
        <div className='loan-options-button outline' onClick={() => { window.location.href = window.location.origin; }}>
          Back to home
        </div>
      </div>
    </div>
  );
}
