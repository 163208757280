import React, { useState, useEffect} from 'react';
import { AutoComplete, Input } from "antd";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import './TextInput.scss';
import { makeVar, useReactiveVar } from '@apollo/client';

export const TextInput = (props) => {
  const { label, value, onChange, isPassword, error, children } = props;

  const inputType = isPassword ? "password" : "text"
  const inputClass = error ? 'text-input error' : 'text-input';

  return (
    <div className={inputClass}>
      {
        children || 
          <input placeholder={label} type={inputType} value={value} onChange={(event) => onChange(event.target.value) } />
      }
      {error && <p className='error-message'>{error}</p>}
    </div>
  )
}

export const investmentPropertyAddressVar = makeVar("Investment property address");

export const InputAutocompleteAddress = ({ disabled, onSelect, initialValue, className = '' }) => {
  const placeholder = useReactiveVar(investmentPropertyAddressVar);
  
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    callbackName: 'mapsAutoComplete',
    requestOptions: {
      componentRestrictions: { country: "us" },
      language: 'en'
    },
    debounce: 300
  });

  useEffect(() => {
    if(initialValue)
      setValue(initialValue);
  }, [])

  const handleInput = (value) => {
    setValue(value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      onSelect({ ...results[0], latLng: { lat, lng } });
    } catch (error) {
      console.log('😱 Error: ', error);
    }
  };

  const options = status === 'OK' ? data.map(({ place_id, description }) => ({
    value: description,
    label: description,
  })) : [];

  return (
    <AutoComplete
      value={value}
      onChange={handleInput}
      onSelect={handleSelect}
      options={options}
      disabled={!ready}
      style={{ width: '100%' }}
      className={`autocomplete-address ${className}`}
      popupClassName={className ?? undefined}
    >
      <Input.Search
        placeholder={placeholder}
        enterButton={null}
        className="bold-lith"
        disabled={disabled}
      />
    </AutoComplete>
  );
}