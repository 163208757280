import React, { useRef, useEffect, useState } from 'react';
import './SendLoanForm.scss';

import { Icon } from '../../atoms/Icon/Icon';
import { SendLoanFormSubmitted } from '../SendLoanFormSubmitted/SendLoanFormSubmitted';
import { TextInput } from '../../atoms/TextInput/TextInput';
import { isProduction } from '../../../utils/constants';

export const SendLoanForm = (props) => {
  const { isSelected, setBottomValue } = props;

  return (
    <div {...{ isSelected, setBottomValue }} className='step-section form'>
      <SendLoanFormInner />
    </div>
  );
}

export const sendLeadData = async (data) => {
  try {
    const response = await fetch('https://sbb.constlending.com/lead', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({isProduction: isProduction, ...data})
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

  } catch (error) {
    console.error('Error:', error);
  }
}

export const SendLoanFormInner = (props) => {
  const [formSubmitted, setFormSubmitted] = useState(false)

  const onSubmission = async (formData) => {
    await new Promise(res => setTimeout(res, 2000))
    sendLeadData({...formData, ...props.data})
    setFormSubmitted(true)
  }

  return (
    <>
      {
        !formSubmitted
          ?
          <SendLoanFormContent {...props} onSubmission={onSubmission} />
          :
          <SendLoanFormSubmitted />
      }
    </>
  )
}

const StepSectionWrapper = (props) => {
  const { isSelected, setBottomValue, children } = props;

  const stepRef = useRef()

  useEffect(() => {
    if (stepRef.current && isSelected) {
      setBottomValue(stepRef.current.offsetTop)
    }
  }, [isSelected, stepRef.current])

  return (
    <div className='step-section' ref={stepRef}>
      {children}
    </div>
  );
}

export const SendLoanFormContent = (props) => {
  const { onSubmission, title } = props


  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState(false)

  const validateInput = (label, value) => {
    switch (label) {
      case 'Name':
        return value.trim() === '' ? 'This field is required' : null;
      case 'Phone number':
        return value.trim() === '' ? 'This field is required' : null;
      case 'Email address':
        const emailRegex = /\S+@\S+\.\S+/;
        return !emailRegex.test(value) ? 'Invalid email address' : null;
      default:
        return null;
    }
  }

  const checkErrors = () => {
    const newErrors = {
      name: validateInput('Name', name),
      phoneNumber: validateInput('Phone number', phoneNumber),
      email: validateInput('Email address', email),
    };
    setErrors(newErrors);
    return newErrors;
  }

  const handleSubmission = () => {
    setTouched(true);
    const errors = checkErrors();
    const hasErrors = Object.values(errors).some(error => error !== null);

    if (!hasErrors) {
      onSubmission({ name, phoneNumber, email });
    }
  }

  const handleChange = (setter, label) => (e) => {
    setter(e.target.value);
    if (touched) {
      setErrors({
        ...errors,
        [label]: validateInput(label, e.target.value),
      });
    }
  };

  return (
    <div className='send-loan-form'>
      <div className='icon-container'>
        <Icon svgFilePath={"./assets/icons/icon-coins-blue.svg"} />
      </div>
      <div className='info'>
        <h1 className='title'>
          {title ?? "Where can we send loan options?"}
        </h1>
        <h3 className='description'>
          Enter your contact information below and one of our loan officers will reach out with custom loan options
        </h3>

        <input
          placeholder='Name'
          type='text'
          value={name}
          onChange={handleChange(setName, 'name')}
        />
        <p className='error'>{errors.name}</p>

        <input
          placeholder='Phone number'
          type='text'
          value={phoneNumber}
          onChange={handleChange(setPhoneNumber, 'phoneNumber')}
        />
        <p className='error'>{errors.phoneNumber}</p>

        <input
          placeholder='Email address'
          type='text'
          value={email}
          onChange={handleChange(setEmail, 'email')}
        />
        <p className='error'>{errors.email}</p>

        <div className='loan-options-button' onClick={() => handleSubmission()}>
          Contact me with loan options
        </div>
        <p className='caption'>
          By submitting this form you agree to be contacted by Constitution Lending
        </p>
      </div>
    </div>
  );
}